/*
 * SPDX-FileCopyrightText: 2021 Tilman Vatteroth
 *
 * SPDX-License-Identifier: MIT
 */

@font-face {
  font-family: "Twemoji";
  src: url("twemoji.woff2") format("woff2");
}
